@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-price-slider-track-height: 3px;
    --category-price-slider-main-color: #{$default-primary-base-color};
    --category-price-slider-rail-color: #{$grey5};
}

.CategoryPriceSlider {
    .MuiSlider-thumb {
        border-radius: 50%;
    }
    .MuiSlider-rail {
        background-color: var(--category-price-slider-rail-color);
        opacity: 1;
    }
}
