@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-heading-color: #{$black};
    --category-description-color: #{$grey3};
}

.CategoryDetails {
    &-Heading {
        align-items: flex-end;
        @include desktop {
            margin-bottom: 26px;
        }

        h1 {
            @include desktop {
                padding-right: 20px;
            }
        }
    }

    &-Description {
        p, span {
            font-size: 16px;
            line-height: 1.5;
            color: var(--category-description-color);
        }

        @include mobileAndTablet {
            margin-bottom: 10px;
        }
    }
}
