@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.CategoryPaginationLink {
    --pagination-accent-color: #{$blue50};
    --pagination-active-link-border-radius: 5px;
    &_isArrow {
        svg {
            width: 8px;
        }
    }
}
