@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-tree-heading-color: #{$black};
    --category-tree-heading-font: #{$font-primary};
    --category-tree-category-color:  #{$black};
    --category-tree-category-color-hover: #{$default-primary-base-color};
    --category-tree-category-accent-color: #{$default-primary-base-color};
    --category-tree-back-color: #{$black};
    --category-tree-radius: 0;
}

.CategoryTree {
    &.ExpandableContent {
        .ExpandableContent-Heading {
            @include desktop {
                letter-spacing: normal;
            }
        }
    }

    &-Category {
        &:hover {
            font-weight: bold;
            color: var(--category-tree-category-color-hover);
        }
    }

    &-BackButton {
        button {
            font-weight: bold;
            font-size: 13px;
            text-transform: uppercase;

            span::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -5px;
                height: 1px;
                background-color: $grey5;
            }
        }
        svg {
            margin-right: 10px;
            width: 4px;
            height: 8px;
            path {
                fill: var(--category-tree-back-color);
                stroke: none;
            }
        }
    }
}
