@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-sort-color: #{$grey3};
    --category-sort-arrow-color: #{$black};
    --category-sort-border-color: #{$grey4};
    --category-sort-option-hover-color: #{$default-primary-base-color};
    --category-sort-radius: 0;
    --category-sort-width: 180px;
    --category-sort-mobile-width: 154px;
    --category-sort-desktop-width: 216px;
    --category-sort-height: 48px;
}
.CategorySort {
    & > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        width: 8px;
        height: 4px;
        path {
            stroke: var(--category-sort-arrow-color);
        }

        @include mobileAndTablet {
            margin-top: 3px;
        }

        @include desktop {
            display: block;
        }
    }

    &-AdditionalLabel {
        display: none;
    }

    .Field_type_select .FieldSelect {
        &-Select {
            @include desktop {
                padding: 12px 17px;
            }
        }

        .ChevronIcon {
            display: none;
        }

        &-Clickable {
            .ChevronIcon {
                right: 22px;
            }
        }
    }
}
