@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-filter-overlay-expandable-content-heading-color: #{$black};
    --category-filter-overlay-heading-background: #{$white};
    --category-filter-overlay-heading-color: #{$black};
    --category-filter-overlay-heading-icon-color: #{$default-primary-base-color};
    --category-filter-overlay-button-close-radius: 0;
}

.CategoryFilterOverlay {
    .ExpandableContent {
        &-Heading {
            @include mobile {
                font-size: 16px;
            }
        }
        &-Button {
            svg {
                width: 8px;
                height: 4px;
                path {
                    fill: var(--category-filter-overlay-expandable-content-heading-color);
                }
            }
        }
    }

    &-Icon_filters {
        path {
            stroke: var(--category-filter-overlay-heading-icon-color);
        }
    }

    .ResetButton {
        @include mobile {
            --reset-button-background: #{$white};
            --reset-button-label-color: #{$default-primary-base-color};
            padding: 16px;
            border-radius: 0;
            text-transform: uppercase;
            border: solid 1px $default-primary-base-color;
            &-Label {
                font-weight: 700;
            }
            &-CloseIcon {
                display: none;
            }
        }
    }

}
