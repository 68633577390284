@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --category-bellow-heading-color: #{$black};
    --category-additional-description-color: #{$grey3};
}

.CategoryDetailsBellow{
    @include tablet {
        margin-top: 30px;
    }

    @include mobile {
        margin: 0 20px;
    }

     &-AdditionalDescription {
         &, p, span {
            font-size: 16px;
         }
     }
}
